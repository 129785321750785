import { useEffect, useState } from "react";
import axiosClient from "../../../../utils/axios";
import useSmartToast from "../../../../hooks/SmartToast";
import {
  Box,
  Button,
  ButtonGroup,
  HStack,
  SimpleGrid,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Text
} from "@chakra-ui/react";
import { Doughnut } from "react-chartjs-2";
import "chart.js/auto";
import { useRouter } from "next/router";

export default function VehicleStatsChart({
  updateType = null,
  type = null,
  city = null,
  updateCity = null
}) {
  const [stats, setStats] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [options, setOptions] = useState<any>(null);
  const [vehicleFilter, setVehicleFilter] = useState(city || "ALL");
  // const [pieData, setPieData] = useState<any>(null);
  // const [labels, setChartLabels] = useState<any>(null);
  const toast = useSmartToast();
  const router = useRouter();
  const legendTitles = {
    purchaseDealer: "Purchase Dealer",
    litigation: "Litigation",
    vendor: "Vendor",
    driver: "Driver",
    garage: "Garage",
    parking: "Parking",
    readyInGarage: "Ready In Garage",
    other: "Others"
  };

  const getVehicleStats = async () => {
    try {
      let params: any = {
        city: null
      };
      if (vehicleFilter === "MUMBAI" || vehicleFilter === "PUNE") {
        params.city = vehicleFilter;
      }
      const { data } = await axiosClient.get("/stats/vehicle", { params });
      setStats(data);
    } catch (error) {
      console.log(error);
      toast.error(error, "An error occurred while fetching vehicle stats.");
    }
  };

  const setLabels = () => {
    const labels = Object.keys(stats);
    return labels
      .filter((label: string) => {
        if (label === "total") {
          return false;
        }
        return true
      })
      .map((label: string) => {
        return getLabel(label);
      });
  };

  const getLabel = (label: string) => {
    return legendTitles[label];
  };

  const getKeyByValue = (value: string) => {
    return Object.keys(legendTitles).find((key) => legendTitles[key] === value);
  };

  useEffect(() => {
    getVehicleStats();
  }, [vehicleFilter]);

  // console.log(pieData);
  // console.log(setLabels());

  useEffect(() => {
    if (stats) {
      // console.log(Object.keys(stats));
      // console.log(Object.values(stats));
      const labels = setLabels();
      const pieData = Object.keys(stats)
        .filter((key) => {
          if (key === "total") {
            return false;
          }
          return true
        })
        .map((key) => {
          return stats[key];
        });
        console.log(pieData);
        
      setData({
        labels: labels,
        datasets: [
          {
            label: "Vehicles",
            data: pieData,
            backgroundColor: [
              "#EDF2F7",
              "#FED7D7",
              "#FEFCBF",
              "#C6F6D5",
              "#BEE3F8",
              "#e9d8fd",
              "#FEEBC8",
              "#ffabff"
            ],
            borderColor: [
              "#2D3748",
              "#9B2C2C",
              "#975A16",
              "#276749",
              "#2C5282",
              "#553c9a",
              "#9C4221",
              "#521B41"
            ]
          }
        ]
      });

      setOptions({
        plugins: {
          legend: {
            position: "right",
            labels: {
              padding: 26
            }
          }
        },
        onClick: (e: any) => {
          const t = e.chart.tooltip.dataPoints[0].label;
          // console.log(type);
          // console.log(getKeyByValue(t));
          if (updateType) {
            updateType(getKeyByValue(t));
          } else {
            router.push("/reports/stats?filter=" + getKeyByValue(t) + "&city=" + vehicleFilter);
          }
        },
        responsive: true,
        maintainAspectRatio: false
      });
    }
  }, [stats]);

  function statRenderer(stat: any, index: number) {
    return (
      <Stat
        border="1px"
        borderColor="gray.300"
        p="4"
        rounded="md"
        transition={"all .2s ease-in-out"}
        _hover={{
          cursor: "pointer",
          bg: "gray.50",
          borderColor: "gray.400"
        }}
        onClick={() => {
          // console.log(type);
          // console.log(stat);
          if (updateType) {
            updateType(stat);
          } else {
            router.push("/reports/stats?filter=" + stat + "&city=" + vehicleFilter);
          }
        }}
        opacity={type ? (type === stat || type === "" ? 1 : 0.5) : 1}
      >
        <StatLabel>{getLabel(stat)}</StatLabel>
        <StatNumber>{stats[stat]}</StatNumber>
      </Stat>
    );
  }


  return (
    <Stack>
      <HStack justify={"space-between"}>
        <Text fontSize={"2xl"} fontWeight={"bold"}>
          Vehicles ({stats?.total})
        </Text>
        <ButtonGroup isAttached variant={"outline"} >
          <Button
            variant={vehicleFilter === "ALL" ? "am" : "outline"}
            onClick={() => {
              setVehicleFilter("ALL");
              if (updateCity) {
                updateCity("ALL");
              }
            }}
          >
            All
          </Button>
          <Button
            variant={vehicleFilter === "MUMBAI" ? "am" : "outline"}
            onClick={() => {
              setVehicleFilter("MUMBAI");
              if (updateCity) {
                updateCity("MUMBAI");
              }
            }}
          >
            Mumbai
          </Button>
          <Button
            variant={vehicleFilter === "PUNE" ? "am" : "outline"}
            onClick={() => {
              setVehicleFilter("PUNE");
              if (updateCity) {
                updateCity("PUNE");
              }
            }}
          >
            Pune
          </Button>
        </ButtonGroup>
      </HStack>
      <SimpleGrid columns={2} spacing={4}>
        <Box
          h={96}
          border={"2px"}
          borderColor={"gray.200"}
          py={5}
          rounded={"md"}
        >
          {data && <Doughnut data={data} options={options} />}
        </Box>
        <SimpleGrid columns={2} spacing={2}>
          {stats &&
            Object.keys(stats)
              .sort((a, b) => {
                if (a === "garage" && b === "readyInGarage") return 1;
                if (a === "readyInGarage" && b === "garage") return 1;
                if (a === "garage" || a === "readyInGarage") return -1;
                return 0;
              })
              .reverse().filter((stat: any) => {
                if (stat !== "total") {
                  return true;
                }
              })
              .map((stat: any, index: number) => statRenderer(stat, index))}
        </SimpleGrid>
      </SimpleGrid>
    </Stack>
  );
}
